export const ProductActionTypes = {
    FETCH_PRODUCT: "FETCH_PRODUCT",
    FETCH_PRODUCT_ERROR: "FETCH_PRODUCT_ERROR",
    DELETE_PRODUCT: "DELETE_PRODUCT",
    DELETE_PRODUCT_ERROR: "DELETE_PRODUCT_ERROR",
    ADD_PRODUCT: "ADD_PRODUCT",
    ADD_PRODUCT_ERROR: "ADD_PRODUCT_ERROR",
    UPDATE_PRODUCT: "UPDATE_PRODUCT",
    UPDATE_PRODUCT_ERROR: "UPDATE_PRODUCT_ERROR",
    CLEAR_ERROR: "CLEAR_ERROR",
    LOADING_TRUE: "LOADING_TRUE",
    FETCH_PRODUCT_CSV_NAME: "FETCH_PRODUCT_CSV_NAME",
    FETCH_PRODUCT_CSV_NAME_ERROR: "FETCH_PRODUCT_CSV_NAME_ERROR",
    FETCH_PRODUCT_CSV_NAME_LOADING: "FETCH_PRODUCT_CSV_NAME_LOADING",
    SET_PRODUCT_CSV: "SET_PRODUCT_CSV",
    SET_PRODUCT_CSV_LOADING: "SET_PRODUCT_CSV_LOADING",
    SET_PRODUCT_CSV_ERROR: "SET_PRODUCT_CSV_ERROR",


}