export const PresetProductActionTypes = {
    FETCH_PRODUCT: "FETCH_PRODUCT",
    FETCH_PRODUCT_ERROR: "FETCH_PRODUCT_ERROR",
    DELETE_PRODUCT: "DELETE_PRODUCT",
    DELETE_PRODUCT_ERROR: "DELETE_PRODUCT_ERROR",
    ADD_PRODUCT: "ADD_PRODUCT",
    ADD_PRODUCT_ERROR: "ADD_PRODUCT_ERROR",
    UPDATE_PRODUCT: "UPDATE_PRODUCT",
    UPDATE_PRODUCT_ERROR: "UPDATE_PRODUCT_ERROR",
    SET_PRESET_PRODUCT_ACTIVE:"SET_PRESET_PRODUCT_ACTIVE",
    CLEAR_ERROR: "CLEAR_ERROR",
    LOADING_TRUE: "LOADING_TRUE",
    SET_NULL:"SET_NULL"

}